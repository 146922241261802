<template>
    <div class="row m-t-xs">
        <div class="col-12">

            <h1 class="table-heading">{{$t('sites.create_site')}}</h1>
            <div class="card">
                <div class="card-body">
                    <Form @submit="(values, {resetForm}) => createSite(values, resetForm)" :validation-schema="schema" class="row g-3">
                        <div class="col-12 form-group">
                            <label for="inputClient" class="form-label">{{$t('global.client')}} <span class="text-danger">*</span></label>
                            <Field id="inputClient" as="select" name="client_id" class="form-select" v-model="site.client_id" @change="updatePrefix">
                                <option value="-1" disabled selected> {{$t('global.select', {text: 'a Client'})}} </option>
                                <option v-for="client in clients" :key="'clientselector-' + client.id" :value="client.id">{{client.name}}</option>
                            </Field>
                            <ErrorMessage name="client_id" class="error-feedback">
                                <span role="alert" class="error-feedback"> Please select a client </span>
                            </ErrorMessage>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputTier" class="form-label"> {{$t('sites.tier')}} <span class="text-danger"> *</span> </label>
                            <Field id="inputTier" as="select" class="form-select" name="tier" v-model="site.tier">
                                <option value="" selected disabled> {{$t('sites.new.select_tier')}} </option>
                                <option v-for="tier in tiers" :value="tier" :key="tier.index"> {{tier}} </option>
                            </Field>
                            <ErrorMessage name="tier" class="error-feedback"> </ErrorMessage>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputSiteName" class="form-label">{{$t('sites.name')}} <span class="text-danger">*</span></label>
                            <Field type="text" name="name" class="form-control" id="inputSiteName" placeholder="Site Name" v-model="site.name"/>
                            <ErrorMessage name="name" class="error-feedback"/>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputSiteReference" class="form-label">{{$t('sites.reference')}} <span class="text-danger">*</span></label>
                            <div class="flex-center">
                                <div class="col-2">
                                    <input type="text" class="form-control client-prefix" readonly :value="currentPrefix"> 
                                </div>
                                <Field name="reference" v-model="site.reference" class="form-control" id="inputSiteReference" placeholder="Site Reference"/>
                                <ErrorMessage name="reference" class="error-feedback"/>
                            </div>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputAddress" class="form-label">{{$t('sites.address_one')}} <span class="text-danger">*</span></label>
                            <Field name="address_one" class="form-control" id="inputAddress" v-model="site.address_one"/>
                            <ErrorMessage name="address_one" class="error-feedback">
                                <span role="alert" class="error-feedback"> Address 1 is a required field </span>
                            </ErrorMessage>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputAddress2" class="form-label">{{$t('sites.address_two')}} <span class="text-danger">*</span></label>
                            <Field name="address_two" class="form-control" id="inputAddress2" v-model="site.address_two"/>
                            <ErrorMessage name="address_two" class="error-feedback">
                                <span role="alert" class="error-feedback"> Address 2 is a required field </span>
                            </ErrorMessage>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputCity" class="form-label">{{$t('sites.town')}}</label>
                            <Field name="address_three" class="form-control" id="inputCity" v-model="site.address_three"/>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputCounty" class="form-label">{{$t('sites.county')}}</label>
                            <Field name="address_four" class="form-control" id="inputCounty" v-model="site.address_four"/>
                        </div>

                        <div class="col-12 form-group">
                            <label for="inputPostcode" class="form-label">{{$t('sites.postcode')}} <span class="text-danger">*</span></label>
                            <Field name="postcode" class="form-control" id="inputPostcode" v-model="site.postcode"/>
                            <ErrorMessage name="postcode" class="error-feedback">
                                <span role="alert" class="error-feedback"> Postcode is a required field </span>
                            </ErrorMessage>
                        </div>
                        <div class="form-group col-12">
                            <label for="inputTelNumber" class="form-label">{{$t('sites.telephone')}}</label>
                            <Field name="telephone" class="form-control" id="inputTelNumber" v-model="site.telephone"/>
                        </div>

                        <div class="col-12 form-group">
                            <button type="submit" class="btn btn-primary">{{$t('sites.finish_and_create')}}</button>
                        </div>
                    </Form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import SiteService from '../../services/site.service';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import clientService from '../../services/client.service';

export default {
    name: 'NewSite',
    components: {Form, Field, ErrorMessage},
    data(){
        const schema = yup.object().shape({
            client_id: yup.number().required(),
            tier: yup.string().required(),
            name: yup.string().required().min(2, 'Site name must be at least 2 character long'),
            reference: yup.number().min(100),
            postcode: yup.string().required(),
            address_one: yup.string().required(),
            address_two: yup.string().required(),
            adrress_three: yup.string(),
            adress_four: yup.string(),
            telephone: yup.number(),
        });
        return {
            site: {
                tier: "",
                name: "",
                reference: "101",
                postcode: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                telephone: "",
                client_id: -1
            },
            submitting: false,
            clients: [],
            currentPrefix: '--',
            schema: schema,
            tiers: [
                "Full VARS System",
                "Tablet Only",
                "Parking Only",
                "Portal Only"
            ]
        }
    },
    mounted() {
        this.getClients();
    },
    methods: {
        createSite(values, reset) {
            // this.site.client_id = parseInt(this.site.client_id);
            this.submitting = true;
            SiteService.createSite(values)
            .then(response => {
                this.$success(this.$t('sites.new.create_site_success'));
                this.$router.push(`/sites/${response.data.site}`);
            })
            .catch(error => {
                this.$error(this.$t('sites.new.create_site_error'), error);
                this.submitting = false;
            })
            reset();
        },
        getClients() {
            clientService.getClients()
            .then(response => {
                this.clients = response.data.clients;
            })
            .catch(error => {
                this.$error(this.$t('sites.new.load_clients_error'))
            })
        },
        updatePrefix() {
            let currentClient = this.clients.filter(client =>  client.id = this.site.client_id)[0];
            this.currentPrefix = currentClient.prefix || this.currentPrefix;
            SiteService.getSiteReference(currentClient.id)
            .then(response => {
                this.site.reference = response.data.reference || "101";
            })
            .catch(error => {
                this.$error(this.$t('sites.new.site_ref_error'), error);
            })
        }
    },
    computed: {
        clients: function(){
            return this.$store.state.auth.user.clients.sort((a,b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        },
        currentClient: function(){
            return this.$store.state.auth.user.clients.filter((c) => {
                return parseInt(c.id) === parseInt(this.$store.state.auth.user.selectedClient);
            })[0];
        }
    }
}
</script>

<style scoped>
    .table-heading {
        margin-bottom: 15px; 
    }

    .btn-container {
        width: fit-content;
        margin: auto;
        margin-top: 25px;
    }

    .btn-container button {
        font-size: 16px;
        padding: 10px 20px;
    }

    .flex-center {
        flex-wrap: nowrap;
    }

    .client-prefix {
        text-align: center; 
        border-radius: 5px 0 0 5px;
    }

    #inputSiteReference {
        border-radius: 0 5px 5px 0;
    }
</style>